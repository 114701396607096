import * as React from 'react';
import styles from './styles.module.scss';
import {useLocation, withRouter, useHistory} from 'react-router-dom';
import leftArrow from '../../assets/images/leftArrow.png';
import rightArrow from '../../assets/images/rightArrow.png';
import tick from '../../assets/images/tick.png';
import cross from '../../assets/images/cross.png';
import {useAppSelector} from '../../app/hooks';
import {selectConfiguration} from '../../features/configuration/configurationSlice';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Sentiment from '../../components/Sentiment';
import Feedback from '../../components/Feedback';
import {SentimentOption} from '../../data/interfaces/kioskConfiguration';
import FeedbackService from '../../services/FeedbackService';

const requestAdditionalFeedbackText =
  'Would you like to provide additional feedback?';
const additionalFeedbackText = 'Please enter additional feedback';
const placeholderText = 'Please enter additional feedback...';
const yesButtonColor = '#00661A';
const noButtonColor = '#B40404';

type Service = {
  text: string;
  image: string;
};

type TicketInformation = {
  text: string;
  ticketCategory: string;
  ticketSubcategory: string;
  ticketPriority: string;
  ticketIsExternal: boolean;
  ticketNotify: Notify;
};

type Notify = {
  notifyId: string;
  notifyType: string;
};

type LocationState = {
  sentiment: SentimentOption;
  service: Service;
  subService: Service;
  ticketInformation: TicketInformation;
};

function AdditionalFeedbackPage() {
  const history = useHistory();
  const {state} = useLocation<LocationState>();
  const configuration = useAppSelector(selectConfiguration);
  const conf = configuration?.configuration;
  const kioskId = configuration?.configuration?.kiosk_id;
  const sentiment = state.sentiment;
  const service = state.service;
  const subService = state.subService;
  const hasSubService = Object.values(subService).every(el => el === undefined);
  const ticketInformation = state.ticketInformation;
  const [feedbackText, setFeedbackText] = React.useState('');
  const [leaveAdditionalFeedback, setLeaveAdditionalFeedback] =
    React.useState(false);
  const raiseTicket = true;

  function leaveFeedback() {
    setLeaveAdditionalFeedback(true);
  }

  function goBack() {
    history.goBack();
  }

  async function next() {
    await FeedbackService.createTicket(
      kioskId!,
      ticketInformation.text,
      ticketInformation.ticketCategory,
      ticketInformation.ticketSubcategory,
      ticketInformation.ticketPriority,
      ticketInformation.ticketIsExternal,
      ticketInformation.ticketNotify?.notifyType,
      ticketInformation.ticketNotify?.notifyId,
      feedbackText,
    );
    if (!sentiment) {
      if (hasSubService) {
        history.push({
          pathname: `/finish/request/${service.text}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      } else {
        history.push({
          pathname: `/finish/request/${service.text}/${subService.text}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      }
    } else {
      if (hasSubService) {
        history.push({
          // prettier-ignore
          pathname: `/finish/${sentiment.rating.name.toLowerCase()}/${service.text}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      } else {
        history.push({
          pathname: `/finish/${sentiment.rating.name}/${service.text}/${subService.text}`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      }
    }
  }

  if (!conf) {
    history.replace('/error');
    return <></>;
  }
  if (!leaveAdditionalFeedback) {
    return (
      <div>
        <Header
          locationText={conf!.location}
          headingText={requestAdditionalFeedbackText}
          logoURI={conf!.configuration.logo}
        />
        <div className={styles.selectedOptionsLeaveFeedback}>
          {sentiment ? (
            <Sentiment sentiment={sentiment!} size={'small'} />
          ) : null}
          {!hasSubService ? (
            <Feedback
              iconURI={subService!.image}
              color={conf!.configuration.primaryBrandColour}
              size={'small'}
              disabled
            />
          ) : (
            <Feedback
              iconURI={service!.image}
              color={conf!.configuration.primaryBrandColour}
              size={'small'}
              disabled
            />
          )}
        </div>
        <div className={styles.backButton}>
          <Button
            onClick={() => {
              leaveFeedback();
            }}
            text={'Yes'}
            leftIcon={tick}
            color={yesButtonColor}
            textColor={'white'}
            small
          />
          <Button
            onClick={() => {
              next();
            }}
            text={'No'}
            leftIcon={cross}
            color={noButtonColor}
            textColor={'white'}
            small
          />
        </div>
        <div className={styles.backButtonLeaveFeedback}>
          <Button
            onClick={() => {
              goBack();
            }}
            text={'Go Back'}
            leftIcon={leftArrow}
            border
            small
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.additionalFeedbackPageRoot}>
        <Header
          locationText={conf!.location}
          headingText={additionalFeedbackText}
          logoURI={conf!.configuration.logo}
          hasSubOption={service && subService ? true : false}
        />
        <div className={styles.selectedOptions}>
          {sentiment ? (
            <Sentiment sentiment={sentiment!} size={'tiny'} />
          ) : null}
          {!hasSubService ? (
            <Feedback
              iconURI={subService!.image}
              color={conf!.configuration.primaryBrandColour}
              size={'tiny'}
              disabled
            />
          ) : (
            <Feedback
              iconURI={service!.image}
              color={conf!.configuration.primaryBrandColour}
              size={'tiny'}
              disabled
            />
          )}
        </div>
        <div className={styles.inputTextContainer}>
          <textarea
            className={styles.inputText}
            maxLength={200}
            placeholder={placeholderText}
            onChange={e => setFeedbackText(e.target.value)}
          />
        </div>
        <div className={styles.backButton}>
          <Button
            onClick={() => {
              goBack();
            }}
            text={'Go Back'}
            border={true}
            leftIcon={leftArrow}
            small
          />
          <Button
            onClick={() => {
              next();
            }}
            text={'Next'}
            rightIcon={rightArrow}
            color={conf!.configuration.primaryBrandColour}
            small
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AdditionalFeedbackPage);
