import * as React from 'react';
import LocationIcon from '../../assets/images/locationIcon.png';
import styles from './styles.module.scss';

type HeaderProps = {
  locationText: string;
  headingText?: string;
  logoURI: string;
  hasSubOption?: boolean;
};

function Header(props: HeaderProps) {
  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div
          className={
            !props.hasSubOption
              ? styles.locationContainer
              : styles.locationContainerWithMargin
          }>
          <img
            className={styles.locationImage}
            alt="location pin"
            src={LocationIcon}
          />
          <span className={styles.locationText}>{props.locationText}</span>
        </div>
        <div className={styles.logoContainer}>
          <img
            className={styles.logoImage}
            alt="company logo"
            src={props.logoURI}
          />
        </div>
      </div>
      {props.headingText ? (
        <div className={styles.headingContainer}>
          <span className={styles.headingText}>{props.headingText}</span>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
