import {Configuration} from '../../data/interfaces/kioskConfiguration';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import ConfigurationService from '../../services/ConfigurationService';
import {RootState} from '../../app/store';

export interface ConfigurationState {
  configuration: Configuration | null;
  status: 'loading' | 'failed' | 'fetched';
}

const initialState: ConfigurationState = {
  configuration: null,
  status: 'loading',
};

export const fetchConfigurationAsync = createAsyncThunk(
  'configuration/fetchConfiguration',
  async (kioskId: string) => {
    return ConfigurationService.fetchConfiguration(kioskId);
  },
);

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConfigurationAsync.pending, state => {
        state.status = 'loading';
        state.configuration = null;
      })
      .addCase(fetchConfigurationAsync.fulfilled, (state, action) => {
        state.status = 'fetched';
        state.configuration = action.payload;
      })
      .addCase(fetchConfigurationAsync.rejected, state => {
        state.status = 'failed';
        state.configuration = null;
      });
  },
});

export const selectConfiguration = (state: RootState) => state.configuration;

export default configurationSlice.reducer;
