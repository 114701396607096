import * as React from 'react';
import {SentimentOption} from '../../data/interfaces/kioskConfiguration';
import styles from './styles.module.scss';

type SentimentProps = {
  sentiment: SentimentOption;
  size: SentimentSize;
  onClick?: () => void;
};

type SentimentSize = 'large' | 'small' | 'tiny';

function Sentiment(props: SentimentProps) {
  return (
    <div className={styles.root}>
      {props.size === 'tiny' ? null : (
        <span className={styles.sentimentText}>
          {props.sentiment.rating.name}
        </span>
      )}
      <div className={styles.sentimentImageContainer} onClick={props.onClick}>
        <img
          className={styles[props.size]}
          src={props.sentiment.rating.image}
          alt={props.sentiment.rating.name}
        />
      </div>
    </div>
  );
}

export default Sentiment;
