import * as React from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {
  fetchConfigurationAsync,
  selectConfiguration,
} from '../../features/configuration/configurationSlice';
import {
  SentimentOption,
  KioskConfiguration,
} from '../../data/interfaces/kioskConfiguration';
import FeedbackService from '../../services/FeedbackService';
import styles from './styles.module.scss';
// @ts-ignore
import LoadingIcon from '../../assets/images/loading.svg';
import Sentiment from '../../components/Sentiment';
import Header from '../../components/Header';
import Button from '../../components/Button';

const _ = require('lodash');

function isValidKioskId(kioskId: string | null): boolean {
  return kioskId !== null && kioskId.length === 9 && kioskId[4] === '-';
}

function SentimentPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(selectConfiguration);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const kioskId = urlParams.get('kiosk_id');
    if (!isValidKioskId(kioskId)) {
      history.replace('/error');
    } else {
      dispatch(fetchConfigurationAsync(kioskId!));
    }
  }, [history, dispatch]);

  React.useEffect(() => {
    const scroll = document.getElementById('scroll');
    if (scroll) {
      scroll.scrollLeft = scroll.scrollWidth / 2;
    }
  }, []);

  async function sentimentSelected(sentiment: SentimentOption) {
    const kioskId = configuration?.configuration?.kiosk_id;
    const conf = configuration?.configuration?.configuration;

    if (!conf || !kioskId) {
      history.replace('/error');
      return;
    }

    if (sentiment.feedback.feedbackOptions?.length > 0) {
      history.push(`/service/${sentiment.rating.name.toLowerCase()}`);
      return;
    }

    await FeedbackService.createSentiment(
      kioskId,
      sentiment.rating.name,
      sentiment.rating.value,
    );
    history.push(`/finish/${sentiment.rating.name}`);
  }

  function startServiceRequest() {
    history.push(`/request`);
  }

  // * Returns ascending order if api doesn't have a flag or orderGoodToBad is `false`. Returns descending order if api returns flag sentimentDirectionGoodToBad as `true`
  function orderSentiments(config: KioskConfiguration): [SentimentOption] {
    const features = config.features;
    const orderGoodToBad =
      'sentimentDirectionGoodToBad' in features
        ? features.sentimentDirectionGoodToBad
        : undefined;
    const sentiments = config.sentimentOptions;
    if (orderGoodToBad === undefined) {
      return _.orderBy(sentiments, ['rating.value'], ['asc']);
    } else if (orderGoodToBad) {
      return _.orderBy(sentiments, ['rating.value'], ['desc']);
    } else {
      return _.orderBy(sentiments, ['rating.value'], ['asc']);
    }
  }

  if (configuration.status === 'loading') {
    return (
      <div className={styles.loadingContainer}>
        <img src={LoadingIcon} alt="" /> <br />
        Loading...
      </div>
    );
  }

  if (
    configuration.status === 'failed' ||
    configuration.configuration === null
  ) {
    history.replace('/error');
    return <></>;
  }

  const kioskConf = configuration.configuration.configuration;
  const features = kioskConf.features;
  const sentiments = orderSentiments(kioskConf);
  const supLogo = kioskConf.supplementaryLogo;
  const sentimentsLength = sentiments.length;

  return (
    <div className={styles.root}>
      <Header
        locationText={configuration.configuration.location}
        headingText={kioskConf.mainScreenTitle}
        logoURI={kioskConf.logo}
      />
      <div className={styles.grid} id="scroll">
        <div
          className={`${styles.gridRow} ${
            styles[sentimentsLength > 8 ? 'start' : 'center']
          }`}>
          {sentiments
            .slice(0, Math.ceil(sentimentsLength / 2))
            .map((sentiment, index) => (
              <span className={styles.sentiment} key={index}>
                <Sentiment
                  sentiment={sentiment}
                  size={'small'}
                  onClick={async () => {
                    await sentimentSelected(sentiment);
                  }}
                />
              </span>
            ))}
        </div>
        <div
          className={`${styles.gridRow} ${
            styles[sentimentsLength > 8 ? 'start' : 'center']
          }`}>
          {sentiments
            .slice(Math.ceil(sentimentsLength / 2), sentimentsLength)
            .map((sentiment, index) => (
              <span className={styles.sentiment} key={index}>
                <Sentiment
                  sentiment={sentiment}
                  size={'small'}
                  onClick={async () => {
                    await sentimentSelected(sentiment);
                  }}
                />
              </span>
            ))}
        </div>
      </div>
      {features.serviceRequest ? (
        <Button
          text={'Request Service'}
          color={kioskConf.primaryBrandColour}
          onClick={() => {
            startServiceRequest();
          }}
        />
      ) : null}
      {supLogo !== null ? (
        <div className={styles.supLogoContainer}>
          <img
            src={supLogo}
            alt=""
            role="presentation"
            className={styles.supLogo}
          />
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(SentimentPage);
