import * as React from 'react';
import {useHistory, useLocation, useParams, withRouter} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectConfiguration} from '../../features/configuration/configurationSlice';
import {
  SentimentFeedbackOption,
  SentimentFeedbackSubOption,
  SentimentRating,
} from '../../data/interfaces/kioskConfiguration';
import FeedbackService from '../../services/FeedbackService';
import Feedback from '../../components/Feedback';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Sentiment from '../../components/Sentiment';
import leftArrow from '../../assets/images/leftArrow.png';
import styles from './styles.module.scss';

const _ = require('lodash');

interface ParamTypes {
  sentimentName: string;
  serviceName: string;
}

function ServiceRequestPage() {
  const history = useHistory();
  const location = useLocation();
  const {sentimentName, serviceName} = useParams<ParamTypes>();
  const configuration = useAppSelector(selectConfiguration);
  const conf = configuration?.configuration;
  const currentSentiment = conf?.configuration.sentimentOptions.filter(
    item => item.rating.name.toLowerCase() === sentimentName,
  )[0];
  const currentService = (
    isServiceRequest(location.pathname)
      ? conf?.configuration.features.serviceRequestOptions
      : currentSentiment?.feedback
  )?.feedbackOptions.filter(item => item.text === serviceName)[0];

  function goBack() {
    history.goBack();
  }

  function isSentimentFeedbackOption(
    input: SentimentFeedbackOption | SentimentFeedbackSubOption,
  ): input is SentimentFeedbackOption {
    return (input as SentimentFeedbackOption).subOptions !== undefined;
  }

  function isServiceRequest(location: string): boolean {
    return location.includes('request');
  }

  async function serviceSelected(
    option: SentimentFeedbackOption | SentimentFeedbackSubOption,
  ) {
    const kioskId = configuration?.configuration?.kiosk_id;

    if (!conf || !kioskId) {
      history.replace('/error');
      return;
    }

    if (isSentimentFeedbackOption(option) && option.subOptions?.length > 0) {
      if (isServiceRequest(location.pathname)) {
        history.push(`/request/${option.text}`);
      } else {
        history.push(`/service/${sentimentName.toLowerCase()}/${option.text}`);
      }
      return;
    }

    let ticketInformation:
      | SentimentFeedbackOption
      | SentimentFeedbackSubOption
      | undefined;
    if (option.raiseTicket) {
      ticketInformation = option;
    } else if (currentService?.raiseTicket) {
      ticketInformation = currentService;
    }

    await navigateToNextScreen(
      kioskId,
      currentSentiment?.rating,
      currentService?.text ?? option.text,
      currentService?.image ?? option.image,
      currentService ? option.text : undefined,
      currentService ? option.image : undefined,
      ticketInformation,
    );
  }

  async function navigateToNextScreen(
    kioskId: string,
    rating?: SentimentRating,
    feedback?: string,
    feedbackIcon?: string,
    feedbackSubOption?: string,
    feedbackSubOptionIcon?: string,
    ticketInformation?: SentimentFeedbackOption | SentimentFeedbackSubOption,
  ) {
    const raiseTicket = !_.isEmpty(ticketInformation);
    if (rating) {
      await FeedbackService.createSentiment(
        kioskId,
        rating.name,
        rating.value,
        feedback,
        feedbackSubOption,
      );
    }

    if (
      configuration?.configuration?.configuration.features.additionalFeedback &&
      ticketInformation
    ) {
      let service = {
        text: feedback,
        image: feedbackIcon,
      };
      let subService = {
        text: feedbackSubOption,
        image: feedbackSubOptionIcon,
      };
      history.push({
        pathname: '/additional-info',
        state: {
          sentiment: currentSentiment,
          service: service,
          subService: subService,
          ticketInformation: ticketInformation,
        },
      });
      return;
    }

    if (ticketInformation) {
      await FeedbackService.createTicket(
        kioskId,
        ticketInformation.text,
        ticketInformation.ticketCategory,
        ticketInformation.ticketSubcategory,
        ticketInformation.ticketPriority,
        ticketInformation.ticketIsExternal,
        ticketInformation.ticketNotify?.notifyType,
        ticketInformation.ticketNotify?.notifyId?.toString(),
      );
    }

    if (isServiceRequest(location.pathname)) {
      if (serviceName) {
        history.push({
          pathname: `/finish/request/${serviceName}/${feedbackSubOption}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      } else {
        history.push({
          pathname: `/finish/request/${feedback}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      }
    } else {
      if (serviceName) {
        history.push({
          pathname: `/finish/${sentimentName}/${serviceName}/${feedbackSubOption}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      } else {
        history.push({
          pathname: `/finish/${sentimentName}/${feedback}/`,
          state: {
            raiseTicket: raiseTicket,
          },
        });
      }
    }
  }

  if (!conf) {
    history.replace('/error');
    return <></>;
  }

  const renderOptions =
    currentService?.subOptions ??
    currentSentiment?.feedback.feedbackOptions ??
    conf.configuration.features.serviceRequestOptions.feedbackOptions;

  return (
    <>
      <div className={styles.serviceRequestPageContainer}>
        <Header
          locationText={conf.location}
          headingText={currentSentiment?.feedback.heading}
          logoURI={conf.configuration.logo}
          hasSubOption={
            currentService && !isServiceRequest(location.pathname)
              ? true
              : false
          }
        />
        <div className={styles.selectedOptions}>
          {!isServiceRequest(location.pathname) ? (
            <Sentiment sentiment={currentSentiment!} size={'tiny'} />
          ) : null}
          {currentService ? (
            <Feedback
              iconURI={currentService!.image}
              color={conf!.configuration.primaryBrandColour}
              size={'tiny'}
              disabled
            />
          ) : null}
        </div>
        <div
          className={
            renderOptions!.length > 2
              ? renderOptions!.length < 5
                ? styles.feedbackOptionsContainerColumnCenterAlignment
                : styles.feedbackOptionsContainerColumn
              : styles.feedbackOptionsContainerRow
          }>
          {renderOptions!.map((option, index) => (
            <Feedback
              key={index}
              text={option.text}
              iconURI={option.image}
              color={conf!.configuration.primaryBrandColour}
              size={'large'}
              onClick={() => serviceSelected(option)}
            />
          ))}
        </div>
        <div className={styles.backButton}>
          <Button
            onClick={() => {
              goBack();
            }}
            text={'Go Back'}
            border={true}
            leftIcon={leftArrow}
          />
        </div>
      </div>
    </>
  );
}

export default withRouter(ServiceRequestPage);
