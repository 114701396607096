import ApiService, {
  CreateSentimentRequest,
  CreateTicketRequest,
  HttpStatus,
} from './ApiService';
import {v4 as uuidv4} from 'uuid';

class FeedbackService {
  async createTicket(
    kioskId: string,
    option: string,
    category?: string,
    subcategory?: string,
    priority?: string,
    isExternal?: boolean,
    notifyType?: string,
    notifyId?: string,
    feedback?: string,
  ): Promise<boolean> {
    const payload: CreateTicketRequest = {
      kiosk_id: kioskId,
      request_uuid: uuidv4(),
      option,
      category: category ?? '',
      isExternal: isExternal ?? false,
      notifyId: Number(notifyId),
      notifyType: notifyType ?? '',
      priority: priority ?? '',
      subcategory: subcategory ?? '',
      feedback: feedback ?? '',
    };
    const response: Response = await ApiService.createTicket(kioskId, payload);
    return (
      response.status === HttpStatus.Created ||
      response.status === HttpStatus.Success
    );
  }

  async createSentiment(
    kioskId: string,
    name: string,
    value: number,
    feedbackOption?: string,
    feedbackSubOption?: string,
  ): Promise<boolean> {
    const payload: CreateSentimentRequest = {
      name,
      value,
      feedbackOption: feedbackOption ?? '',
      feedbackSubOption: feedbackSubOption ?? '',
      kiosk_id: kioskId,
      request_uuid: uuidv4(),
    };

    const response: Response = await ApiService.createSentiment(
      kioskId,
      payload,
    );
    return (
      response.status === HttpStatus.Created ||
      response.status === HttpStatus.Success
    );
  }
}

export default new FeedbackService();
