import * as React from 'react';
import './styles.modules.scss';

// * Size prop can be `large`: used for feedback tiles on pages `small`: used for feedback tile on acknowledgement page `tiny`: used for feedback tile in header

type size = 'large' | 'small' | 'tiny';

type FeedbackProps = {
  text?: string;
  iconURI: string;
  color: string;
  onClick?: () => void;
  size: size;
  disabled?: boolean;
};

function Feedback(props: FeedbackProps) {
  return (
    <div
      className={
        !props.disabled
          ? 'feedbackButton ' + props.size
          : 'feedbackButton disabled ' + props.size
      }
      style={{backgroundColor: props.color}}
      onClick={props.onClick}>
      <div className={'feedbackTextContainer ' + props.size}>
        <span className={'feedbackText ' + props.size}>{props.text}</span>
      </div>
      <div className={'feedbackIconContainer ' + props.size}>
        <img
          className={'feedbackIcon ' + props.size}
          alt={'feedback - ' + props.text}
          src={props.iconURI}
        />
      </div>
    </div>
  );
}

export default Feedback;
