import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {store} from './app/store';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import SentimentPage from './pages/SentimentPage';
import ServiceRequestPage from './pages/ServiceRequestPage';
import ThankYouPage from './pages/ThankYouPage';
import ErrorPage from './pages/ErrorPage';
import AdditionalFeedbackPage from './pages/AdditionalFeedbackPage';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={SentimentPage} />
            {/*There is a bug in the QR code generation which embeds an extra forward slash in the URL*/}
            <Route exact path="//" component={SentimentPage} />
            <Route
              exact
              path="/service/:sentimentName"
              component={ServiceRequestPage}
            />
            <Route
              exact
              path="/service/:sentimentName/:serviceName"
              component={ServiceRequestPage}
            />
            <Route exact path="/request/" component={ServiceRequestPage} />
            <Route
              exact
              path="/request/:serviceName"
              component={ServiceRequestPage}
            />
            <Route
              exact
              path="/finish/request/:serviceName"
              component={ThankYouPage}
            />
            <Route
              exact
              path="/finish/request/:serviceName/:subServiceName"
              component={ThankYouPage}
            />
            <Route
              exact
              path="/finish/:sentimentName"
              component={ThankYouPage}
            />
            <Route
              exact
              path="/finish/:sentimentName/:serviceName"
              component={ThankYouPage}
            />
            <Route
              exact
              path="/finish/:sentimentName/:serviceName/:subServiceName"
              component={ThankYouPage}
            />
            <Route
              exact
              path="/additional-info/"
              component={AdditionalFeedbackPage}
            />
            <Route component={ErrorPage} />
          </Switch>
        </div>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
