import React from 'react';
import {Link} from 'react-router-dom';
import ErrorIcon from '../../assets/images/error.svg';
import styles from './styles.module.scss';
import {useAppSelector} from '../../app/hooks';
import {selectConfiguration} from '../../features/configuration/configurationSlice';

const ErrorPage = () => {
  const configuration = useAppSelector(selectConfiguration);
  const kioskId = configuration?.configuration?.kiosk_id;
  return (
    <div className={styles.errorPageContainer}>
      <img src={ErrorIcon} alt="" /> <br />
      Page unavailable <br />
      {kioskId ? (
        <Link to={`/?kiosk_id=${kioskId}`}>Click here to reload</Link>
      ) : null}
    </div>
  );
};

export default ErrorPage;
