import * as React from 'react';
import {useAppSelector} from '../../app/hooks';
import {selectConfiguration} from '../../features/configuration/configurationSlice';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import Header from '../../components/Header';
import styles from './styles.module.scss';
import Feedback from '../../components/Feedback';
import Sentiment from '../../components/Sentiment';

interface ParamTypes {
  sentimentName: string;
  serviceName?: string;
  subServiceName?: string;
}

interface LocationState {
  raiseTicket: boolean;
}

function ThankYouPage() {
  const history = useHistory();
  const location = useLocation();
  const {state} = useLocation<LocationState>();
  const {sentimentName, serviceName, subServiceName} = useParams<ParamTypes>();
  const configuration = useAppSelector(selectConfiguration);
  const conf = configuration?.configuration;
  const raiseTicket = state?.raiseTicket;
  const currentSentiment = conf?.configuration.sentimentOptions.filter(
    item => item.rating.name.toLowerCase() === sentimentName?.toLowerCase(),
  )[0];
  const currentService = (
    isServiceRequest(location.pathname)
      ? conf?.configuration.features.serviceRequestOptions
      : currentSentiment?.feedback
  )?.feedbackOptions.filter(item => item.text === serviceName)[0];

  const currentSubService = currentService?.subOptions?.filter(
    item => item.text === subServiceName,
  )[0];

  function isServiceRequest(location: string): boolean {
    return location.includes('request');
  }

  if (!conf) {
    history.replace('/error');
    return <></>;
  }

  return (
    <>
      <Header locationText={conf.location} logoURI={conf.configuration.logo} />
      <div className={styles.feedbackContainer}>
        {currentSentiment ? (
          <Sentiment sentiment={currentSentiment} size="small" />
        ) : null}

        {currentService ? (
          <div className={styles.feedbackTile}>
            <Feedback
              size="small"
              text={currentService.text}
              iconURI={currentService.image}
              color={conf.configuration.primaryBrandColour}
              disabled
            />
          </div>
        ) : null}
        {currentSubService ? (
          <div className={styles.feedbackTile}>
            <Feedback
              size="small"
              text={currentSubService.text}
              iconURI={currentSubService.image}
              color={conf.configuration.primaryBrandColour}
              disabled
            />
          </div>
        ) : null}
      </div>
      {currentService && raiseTicket ? (
        <div className={styles.ackServiceRequestText}>
          {conf.configuration.ackServiceRequestText}
        </div>
      ) : (
        <div className={styles.ackSentimentText}>
          {conf.configuration.ackSentimentText}
        </div>
      )}
    </>
  );
}

export default ThankYouPage;
