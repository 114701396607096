import * as React from 'react';
import './styles.modules.scss';

type textColor = 'white' | 'black';

type ButtonProps = {
  text: string;
  leftIcon?: string;
  rightIcon?: string;
  color?: string;
  border?: boolean;
  onClick: () => void;
  textColor?: textColor;
  small?: boolean;
};

function Button(props: ButtonProps) {
  return (
    <div
      className={
        props.border
          ? props.small
            ? 'button border small'
            : 'button border'
          : props.small
          ? 'button small'
          : 'button'
      }
      style={{backgroundColor: props.color}}
      onClick={props.onClick}>
      {props.leftIcon ? (
        <img className="icon right-margin" alt={'left'} src={props.leftIcon} />
      ) : null}
      <span className={props.textColor === 'white' ? 'text white' : 'text'}>
        {props.text}
      </span>
      {props.rightIcon ? (
        <img className="icon left-margin" alt={'right'} src={props.rightIcon} />
      ) : null}
    </div>
  );
}

export default Button;
