import {Configuration} from '../data/interfaces/kioskConfiguration';
import ApiService, {HttpStatus} from './ApiService';

class ConfigurationService {
  async fetchConfiguration(kioskId: string): Promise<Configuration | null> {
    const response: Response = await ApiService.fetchApiConfig(kioskId);

    if (!response || response.status !== HttpStatus.Success) {
      throw new Error('Unable to fetch configuration');
    }

    const configuration: Configuration | null = await response.json();

    if (!configuration) {
      throw new Error('Unable to fetch configuration');
    }

    return configuration;
  }
}

export default new ConfigurationService();
